var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-product-list" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "green-divider" }),
      _c("b-table", {
        staticClass: "bv-table",
        attrs: {
          striped: "",
          hover: "",
          items: _vm.productList,
          fields: _vm.productFields,
          "select-mode": "single",
          "selected-variant": "success",
          "th-class": "bv-table-header",
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage
        },
        on: { "row-clicked": _vm.editShopProduct },
        scopedSlots: _vm._u([
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "row-icon-button row-action",
                  class: _vm.$config.icons.general.edit,
                  attrs: { title: "Edit Product" },
                  on: {
                    click: function($event) {
                      return _vm.editShopProduct(row.item)
                    }
                  }
                }),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass:
                    "fa fa-times-circle row-icon-button-danger row-action ml-3",
                  attrs: { title: "Does nothing!" },
                  on: {
                    click: function($event) {
                      return _vm.userActionDelete(row.item)
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "green-divider" }),
      _c(
        "div",
        { staticClass: "page-bar" },
        [
          _c("b-form-select", {
            staticClass: "page-select",
            attrs: {
              variant: "dark",
              id: "perPageSelect",
              size: "sm",
              options: _vm.pageOptions
            },
            model: {
              value: _vm.perPage,
              callback: function($$v) {
                _vm.perPage = $$v
              },
              expression: "perPage"
            }
          }),
          _c("b-pagination", {
            staticClass: "page-buttons",
            attrs: {
              variant: "dark",
              "total-rows": _vm.productList.length,
              "per-page": _vm.perPage,
              align: "fill",
              size: "sm"
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-edit-product",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Edit Product",
            "no-close-on-backdrop": ""
          }
        },
        [
          _c("admin-edit-shop-product", {
            attrs: {
              "is-modal": "",
              modal: "modal-edit-product",
              product: _vm.currentProduct
            },
            on: {
              save: function($event) {
                return _vm.onSave()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }